.infoWindow {
}

div.infoButton {
	font-size:1.0em;
	margin: 5px 0px;
}

button.infoButton {
	margin-top: 15px;
}

@media (min-width:576px) {
	div.infoButton {
		min-width: 300px;
	}
	button.infoButton {
		margin-top: 5px;
	}	
}

button.infoButton {
	font-weight: bold;
	border-radius: 0.25rem;
	border: 1px solid #02093D;
	width: 100%;
	padding: 10px 0px;
}

.infoTitle {
	font-weight: bold;
	color: #02093D;
	margin-bottom: 0px;
}

a {
	color: var(--bs-primary);
}
a:active {
	color: var(--bs-primary);
}

a:hover {
	color: var(--bs-primary);
}

.infoAddress {
	color: #02093D;
	font-style: italic;
}