.pageTitle, .pageTitleWithSub, .pageSubtitle {
	color: var(--bs-gray-dark);	
}

.pageTitle, .pageTitleWithSub {
	font-size: 2.5rem;
}

.pageTitleWithSub {
	margin-bottom: 0px;
}

.pageSubtitle {
	font-size: 20px;
    margin-left: 11px;
}