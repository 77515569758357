.pin-field {
	margin-right: 50%;
	background-color: rgb(248, 249, 250);
	border: 1px solid rgb(204, 204, 204);
	border-radius: 0.3rem;
	font-size: 2rem;
	margin: 0.25rem;
	height: 3.5rem;
	outline: none;
	text-align: center;
	transition-duration: 250ms;
	transition-property: background, color, border, box-shadow, transform;
	width: 3rem;
}

swd-pin-field {
	display: block;	
}