img.thumb {
	margin-top: 5px;
}

img.tumb_logo {
	width: 200px;
	height: 200px;
}

img.tumb_main {
	width: 550px;
	height: 220px;
}

img.tumb_01 {
	width: 300px;
	height: 225px;
}

img.tumb_02 {
	width: 300px;
	height: 225px;
}

img.tumb_03 {
	width: 300px;
	height: 225px;
}

textarea {
	height: 150px;
}