.react-responsive-modal-modal {
	top: 1.5em;
}

.oldPrice {
	text-decoration: line-through;
	font-size: 1.2em;
	color: 	red!important;	/* text-muted */
}

.discountedPrice {
	color: green;
	font-size: 1.2em;
}

@media only screen and (min-width: 768px) {
	.react-responsive-modal-modal {
		width:700px;
		top: 20%;
	}
}

@media only screen and (max-width: 767px) {
	.react-responsive-modal-modal {
		width:90%;
		top: 20%;
	}
}


