.logo {
  overflow: hidden;
}

.navbar {
	text-align: right;
	padding: 0px;
}

.navbar li {
  position: relative;
  list-style: none;
  text-decoration: none;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  text-decoration: none;
}

.roundWhite {
	background-color: #fff;
	border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
	padding-bottom: 10px;
	margin-bottom: 10px;
}

.round {
	border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
	padding: 0px 5px;
}
.bRed {
	background-color: #02093D;
}

.bBlack {
	background-color: #36343a;
}

.buttonRed {
	background-color: #02093D;
	border-color: #02093D;
}

.buttonRed.disabled, .buttonRed:disabled {
	background-color: #02093D;
	border-color: #02093D;
}
