.iconBadge {
	color: #FFFFFF;
	width: 100px;
	height: 70px;	
	background-color: #CD0017;	
	padding: 10px 15px 10px 15px;
	text-align: center;
	border-radius: 0.25rem;
	margin-left: 10px;
}







