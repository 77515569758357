.baseOverlay {
	position: relative;
	
}
.overlay {
	position: absolute;
	left:0;
    top:0;
    background: rgba(255,255,255,.9);
    width:100%;
    height:100%;
}