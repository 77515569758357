.noticeDetail {
	height: 500px;
	border: 3px solid #E2001A;
	border-radius: 0.5rem;
	padding: 5px 5px;
	margin-bottom: 10px;
	overflow-y: auto;
}

.noticeType {
	text-align: left;
	text-decoration: underline;
}

.noticeBrand {
	font-size: 2em;
	font-weight: 600;
	margin-bottom: -10px;	
}

.noticeLocation {
	font-size: 1.1em;
	margin-top: 0px;
	font-style: italic;
}

.noticeSlogan {
	text-align: left;
	font-size: 1.2em;
}