body {
	margin: 0;
	font-family: 'Ropa Sans';
	color: var(--bs-gray-dark);
}

.form-control {
color: va	r(--bs-gray-dark);
}

a {
	text-decoration: none;
	color: #fff;
}

a:active {
	color: white;
}

a:hover {
	color: #fff;
}

a.dark {
	color: var(--bs-gray-dark);
}
a.underline {
	text-decoration: underline;
}

a.dark:active {
	color: var(--bs-gray-dark);
}

a.dark:hover {
	color: var(--bs-gray-dark);
}

link {
	text-decoration: none;
	color: #fff;
}

lik:active {
	color: white;
}

link:hover {
	color: #fff;
}

.textWhite {
	color: #fff;
}

.textRed {
	color: #02093D;
}

.bckRed {
	background-color: #02093D;
}

.cliccable {
	cursor: pointer;
}

.validationMessage, .srv-validation-message {
	color: red;
	border-left: 1px solid red;
	padding-left: 5px;
}

.btn-light {
	background-color: #e8e9eb;
}

.forceTextLeft {
	text-align: left!important;
}

.primaryColor {
	color: var(--bs-primary);
}

.digital {
	font-family: 'Iceland', cursive;
	font-size: 1.5em;
}

.required {
  font-weight: bold;
}

.required::after {
  content: ' *';
  color: red;
}

.faInsideInput {
	float: right;
	margin-right: 6px;
	margin-top: -28px;
	position: relative;
	z-index: 2;
}

.wait_start {
	color: orange;
}

.go {
	color: #00e600;
}

.wait_stop {
	color: orange;
}

.norent {
	color: #d2d2d2;
}