.icon-boxes {
	padding-top: 0;
	position: relative;
	z-index: 100;
}

.icon-boxes .icon-box {
	padding: 30px 20px;
	position: relative;
	overflow: hidden;
	background: #fff;
	box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
	transition: all 0.3s ease-in-out;
	border-radius: 10px;
}

#main .container .container-fluid .row .col {
	text-align: center;
}

#hero {
	width: 100%;
	height: 80vh;
	overflow: hidden;
	position: relative;	
	background-size: cover;
	position: relative;
	margin-bottom: -90px;
	z-index: 99;
	transition: 0.3s;
}

#hero:before {
	content: "";
	background: #21252940;
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero .carousel-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}

#hero p {
	width: 80%;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	margin: 0 auto 30px auto;
	color: #fff;
}

.img-logo {
	max-height: 200px;
	max-width: 200px
}

.webFooter {
	font-size: 0.8em;
	font-style: italic;
	text-align: center;	
}

@media (min-width: 1024px) {
	#hero p {
		width: 60%;
	}
}

@media (max-width: 768px), (max-height: 700px) {
	#hero {
		padding: 100px 0;
	}
	#hero h2 {
		font-size: 28px;
	}
}
