.card {
	margin-left: auto;
	margin-right: auto;
}

.card-title {
	color: var(--bs-gray-dark);
	font-weight: bold;
}

.badge {
	width: 70px;
	height: 70px;	
	background-color: #02093D;	
	position: absolute;
	top: 0px;
	right: 0px;
	padding: 17px 13px 13px 13px;
	-webkit-animation: pulse 2s infinite ease-in-out;
	-o-animation: pulse 2s infinite ease-in-out;
	-ms-animation: pulse 2s infinite ease-in-out; 
	-moz-animation: pulse 2s infinite ease-in-out; 
	animation: pulse 2s infinite ease-in-out;
}

@-webkit-keyframes pulse {
    0% { -webkit-transform: scale(0.1); opacity: 0.1; }
    50% { -webkit-transform: scale(1); opacity: 1; }
    100% { -webkit-transform: scale(0.1); opacity: 0.1; }
}

@keyframes pulse {
    0% { transform: scale(0.1); opacity: 0.1; }
    50% { transform: scale(1); opacity: 1; }
    100% { transform: scale(0.1); opacity: 0.1; }
}







